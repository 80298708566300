export default{
	tableTitleBackColor(){
		return "background-color: #f2f2f2;color:#666;text-align: center;"
	},
	tableCss(){
		return "width: 100%;font-weight: 500;font-size: 14px;text-align: center;"
	},
	cellStyle(){
		return "text-align: center;"
	}
}
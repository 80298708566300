import request from '@/api/request.js'

//获取部门列表
export function getDepartmentListFn (data){
  return request({
    url:'/village/department',
    method:'get',
    params:data
  })
}
//新增部门
export function addDepartmentFn (data){
  return request({
    url:'/village/department/add',
    method:'post',
    data:data
  })
}
//获取部门详情
export function getDepartmentDetailsFn (data){
  return request({
    url:'/village/department/' + data.id,
    method:'get',
    params:data
  })
}
//编辑部门
export function editDepartmentFn (data){
  return request({
    url:'/village/department/'+ data.id +'/update',
    method:'post',
    data:data
  })
}
//删除部门
export function deleteDepartmentFn (data){
  return request({
    url:'/village/department/'+ data.id +'/delete',
    method:'post',
    data:data
  })
}

//获取岗位列表
export function getPostListFn (data){
  return request({
    url:'/village/position',
    method:'get',
    params:data
  })
}
//删除岗位
export function deletePostFn (data){
  return request({
    url:'/village/position/' + data.id +'/delete',
    method:'post',
    data:data
  })
}
//新增岗位
export function addPostFn (data){
  return request({
    url:'/village/position/add',
    method:'post',
    data:data
  })
}

//获取员工列表
export function getStaffListFn (data){
  return request({
    url:'/village/user',
    method:'get',
    params:data
  })
}
//删除员工
export function deleteStaffFn (data){
  return request({
    url:'/village/position/' + data.id +'/delete',
    method:'post',
    data:data
  })
}
//新增员工
export function addStaffFn (data){
  return request({
    url:'/village/user/add',
    method:'post',
    data:data
  })
}
//离职
export function leavePostFn (data){
  return request({
    url:'/village/user/'+ data.id +'/leave',
    method:'post',
    data:data
  })
}
